<template>
  <div class="app">
    <transition name="fade">
      <div class="wrapper" v-if="!isLoad">
        <transition-group
          :name="isPageBack ? 'page-back' : 'page-next'"
          tag="div"
          class="content"
        >
          <component :is="page.component" :key="page.id" class="inner" />
        </transition-group>
        <Navbar />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="isLoad" class="loader">
        <div class="title">
          <svg width="159" height="115" viewBox="0 0 159 115" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9076 115C11.3363 115 8.98742 114.39 6.8611 113.169C4.73478 111.923 3.0535 110.242 1.81726 108.124C0.605754 105.982 0 103.615 0 101.024C0 98.4331 0.605754 96.0788 1.81726 93.9612C3.0535 91.8187 4.73478 90.1371 6.8611 88.9163C8.98742 87.6707 11.3363 87.0479 13.9076 87.0479C17.913 87.0479 21.2261 88.5053 23.8469 91.4201L20.3237 94.8581C18.6177 92.8401 16.4914 91.8311 13.9447 91.8311C12.2882 91.8311 10.78 92.2173 9.4201 92.9896C8.06024 93.737 6.98472 94.8082 6.19353 96.2034C5.42707 97.5985 5.04383 99.2054 5.04383 101.024C5.04383 102.843 5.42707 104.45 6.19353 105.845C6.98472 107.24 8.06024 108.324 9.4201 109.096C10.78 109.843 12.2882 110.217 13.9447 110.217C16.7386 110.217 19.0751 109.046 20.9542 106.704L24.5145 110.105C23.2041 111.699 21.6465 112.92 19.8416 113.767C18.0614 114.589 16.0834 115 13.9076 115Z" fill="#281600"/>
            <path d="M36.8077 115C34.8544 115 33.1114 114.564 31.5784 113.692C30.0455 112.795 28.8464 111.587 27.981 110.067C27.1403 108.523 26.72 106.791 26.72 104.873C26.72 102.98 27.1403 101.261 27.981 99.7161C28.8464 98.1715 30.0455 96.9632 31.5784 96.0913C33.1114 95.1944 34.8544 94.746 36.8077 94.746C38.7362 94.746 40.467 95.1944 41.9999 96.0913C43.5328 96.9632 44.7196 98.1715 45.5602 99.7161C46.4256 101.261 46.8583 102.98 46.8583 104.873C46.8583 106.791 46.4256 108.523 45.5602 110.067C44.7196 111.587 43.5328 112.795 41.9999 113.692C40.467 114.564 38.7362 115 36.8077 115ZM36.8077 110.479C37.7472 110.479 38.6126 110.254 39.4038 109.806C40.195 109.357 40.8255 108.71 41.2952 107.863C41.765 107.016 41.9999 106.019 41.9999 104.873C41.9999 103.752 41.765 102.768 41.2952 101.921C40.8255 101.074 40.195 100.426 39.4038 99.9777C38.6126 99.5293 37.7472 99.305 36.8077 99.305C35.8682 99.305 35.0028 99.5293 34.2116 99.9777C33.4204 100.426 32.7776 101.074 32.2831 101.921C31.8133 102.768 31.5784 103.752 31.5784 104.873C31.5784 105.994 31.8133 106.991 32.2831 107.863C32.7776 108.71 33.4204 109.357 34.2116 109.806C35.0028 110.254 35.8682 110.479 36.8077 110.479Z" fill="#281600"/>
            <path d="M57.2186 115C54.9439 115 53.2132 114.29 52.0264 112.87C50.8396 111.45 50.2462 109.47 50.2462 106.928V95.3439H55.1046V106.33C55.1046 107.626 55.4137 108.647 56.0318 109.395C56.6499 110.117 57.4658 110.479 58.4795 110.479C59.8641 110.479 60.9644 109.968 61.7803 108.946C62.5962 107.925 63.0042 106.629 63.0042 105.06V95.3439H67.8626V114.402H63.3009V112.011H63.0042C62.4355 112.932 61.6319 113.667 60.5935 114.215C59.5798 114.739 58.4548 115 57.2186 115Z" fill="#281600"/>
            <path d="M72.5961 95.3439H77.1578V97.885H77.4545C77.9242 96.9881 78.6536 96.2532 79.6426 95.6802C80.6316 95.0823 81.7195 94.7833 82.9063 94.7833C83.7716 94.7833 84.5628 94.9204 85.2798 95.1944V100.09C84.6123 99.8407 84.0436 99.6663 83.5738 99.5666C83.1288 99.4421 82.6467 99.3798 82.1274 99.3798C80.6934 99.3798 79.5561 99.9029 78.7154 100.949C77.8748 101.996 77.4545 103.316 77.4545 104.91V114.402H72.5961V95.3439Z" fill="#281600"/>
            <path d="M94.8938 115C92.5944 115 90.7277 114.527 89.2936 113.58C87.8843 112.633 86.8953 111.4 86.3267 109.881L90.6659 107.975C91.0615 108.872 91.6178 109.557 92.3348 110.03C93.0765 110.479 93.9295 110.703 94.8938 110.703C95.7839 110.703 96.5256 110.566 97.119 110.292C97.7124 109.993 98.0091 109.519 98.0091 108.872C98.0091 108.249 97.7371 107.788 97.1932 107.489C96.6492 107.165 95.8333 106.879 94.7454 106.629L92.5202 106.144C90.9873 105.77 89.714 105.097 88.7003 104.126C87.6865 103.129 87.1797 101.896 87.1797 100.426C87.1797 99.3299 87.5011 98.3459 88.144 97.4739C88.8115 96.602 89.7016 95.9293 90.8142 95.456C91.9516 94.9826 93.2001 94.746 94.56 94.746C98.4665 94.746 101.075 96.1286 102.385 98.894L98.2316 100.725C97.4899 99.3798 96.3031 98.7071 94.6713 98.7071C93.8306 98.7071 93.1631 98.8691 92.6686 99.1929C92.1741 99.4919 91.9268 99.878 91.9268 100.351C91.9268 101.248 92.7675 101.921 94.4487 102.369L97.2303 103.042C99.1093 103.515 100.519 104.238 101.458 105.209C102.422 106.181 102.905 107.377 102.905 108.797C102.905 110.018 102.546 111.101 101.829 112.048C101.137 112.97 100.172 113.692 98.9363 114.215C97.7248 114.739 96.3773 115 94.8938 115Z" fill="#281600"/>
            <path d="M106.242 95.3439H110.804V97.7355H111.101C111.694 96.8137 112.485 96.0913 113.474 95.5681C114.488 95.02 115.613 94.746 116.849 94.746C118.184 94.746 119.346 95.0698 120.335 95.7176C121.324 96.3404 122.029 97.1376 122.449 98.1092C123.068 97.1127 123.908 96.303 124.971 95.6802C126.059 95.0574 127.333 94.746 128.791 94.746C130.942 94.746 132.574 95.4186 133.687 96.7639C134.824 98.0843 135.393 99.878 135.393 102.145V114.402H130.571V103.042C130.571 101.846 130.287 100.924 129.718 100.277C129.175 99.6289 128.408 99.305 127.419 99.305C126.158 99.305 125.144 99.8158 124.378 100.837C123.636 101.834 123.265 103.154 123.265 104.798V114.402H118.407V103.042C118.407 101.846 118.11 100.924 117.517 100.277C116.948 99.6289 116.132 99.305 115.069 99.305C113.882 99.305 112.918 99.8158 112.176 100.837C111.459 101.834 111.101 103.154 111.101 104.798V114.402H106.242V95.3439Z" fill="#281600"/>
            <path d="M148.897 115C147.018 115 145.325 114.564 143.816 113.692C142.308 112.82 141.121 111.625 140.256 110.105C139.415 108.56 138.995 106.829 138.995 104.91C138.995 103.092 139.415 101.41 140.256 99.8656C141.097 98.2961 142.259 97.0504 143.742 96.1286C145.226 95.2069 146.882 94.746 148.712 94.746C150.665 94.746 152.346 95.1695 153.756 96.0165C155.165 96.8636 156.228 98.022 156.945 99.4919C157.687 100.962 158.058 102.594 158.058 104.387C158.058 104.96 158.021 105.508 157.947 106.032H143.742C143.99 107.551 144.583 108.71 145.522 109.507C146.487 110.279 147.636 110.665 148.972 110.665C150.084 110.665 151.036 110.429 151.827 109.955C152.643 109.457 153.286 108.797 153.756 107.975L157.687 109.918C155.758 113.306 152.829 115 148.897 115ZM153.385 102.556C153.335 101.958 153.113 101.373 152.717 100.8C152.346 100.202 151.815 99.7161 151.123 99.3424C150.455 98.9438 149.664 98.7445 148.749 98.7445C147.587 98.7445 146.586 99.0933 145.745 99.7908C144.929 100.463 144.336 101.385 143.965 102.556H153.385Z" fill="#281600"/>
            <path d="M47.2239 77C41.3187 77 36.143 75.4216 31.6968 72.2647C27.32 69.0392 24.2632 64.6814 22.5264 59.1912L35.4483 53.9412C36.3514 57.1667 37.8104 59.6373 39.8251 61.3529C41.8398 63 44.306 63.8235 47.2239 63.8235C50.4196 63.8235 52.9207 62.7941 54.7269 60.7353C56.6027 58.6765 57.5406 55.7941 57.5406 52.0882V1.64706H71.6088V51.9853C71.6088 57.3382 70.532 61.902 68.3783 65.6765C66.2247 69.3824 63.3068 72.1961 59.6248 74.1176C55.9427 76.0392 51.8091 77 47.2239 77Z" fill="#281600"/>
            <path d="M109.995 77C103.465 77 97.6985 75.1814 92.6965 71.5441C87.6944 67.9069 84.1861 62.7598 82.1714 56.1029L95.4059 50.9559C96.448 54.8676 98.2196 57.9902 100.721 60.3235C103.222 62.6569 106.348 63.8235 110.099 63.8235C113.226 63.8235 115.9 63.0686 118.123 61.5588C120.347 59.9804 121.458 57.8186 121.458 55.0735C121.458 52.3284 120.381 50.098 118.228 48.3824C116.143 46.598 112.392 44.7108 106.973 42.7206L102.388 41.0735C97.5943 39.4265 93.4954 36.9216 90.0913 33.5588C86.6871 30.1275 84.985 25.8382 84.985 20.6912C84.985 16.848 85.9924 13.348 88.0071 10.1912C90.0218 7.03432 92.8702 4.56373 96.5522 2.77941C100.234 0.92647 104.403 0 109.057 0C115.796 0 121.111 1.57843 125.001 4.73529C128.892 7.82353 131.566 11.4608 133.025 15.6471L120.624 20.7941C119.86 18.5294 118.506 16.6422 116.56 15.1324C114.685 13.5539 112.253 12.7647 109.266 12.7647C106.209 12.7647 103.673 13.4853 101.658 14.9265C99.7132 16.299 98.7406 18.1176 98.7406 20.3824C98.7406 22.5784 99.6785 24.4657 101.554 26.0441C103.43 27.5539 106.522 29.0294 110.829 30.4706L115.518 32.0147C121.979 34.2108 126.912 37.1275 130.316 40.7647C133.79 44.3333 135.526 49.0343 135.526 54.8676C135.526 59.6716 134.276 63.7549 131.775 67.1176C129.274 70.4118 126.043 72.8824 122.083 74.5294C118.193 76.1765 114.164 77 109.995 77Z" fill="#281600"/>
          </svg>
        </div>
        <Preloader />
      </div>
    </transition>

    <ModalMoreCourses />
    <Article />
    <Section />
    <ArticleSection />
    <Test />

    <Ads />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Ads from '@/components/ads/Ads'
import Preloader from "@/components/base/Preloader";
import ModalMoreCourses from "@/components/ModalMoreCourses";
import Navbar from "./components/base/Navbar";
import Home from "./views/Home";
import Articles from "./views/Articles";
import Article from "./components/article/Article";
import ArticleSection from "./components/article/ArticleSection";
import Section from "./components/article/Section";
import Tests from "./views/Tests";
import Test from "@/components/test/questions/Wrapper";

export default {
  name: "App",
  components: {
    Navbar,
    Home,
    Articles,
    Tests,
    Test,
    ModalMoreCourses,
    Preloader,
    Article,
    Section,
    ArticleSection,
    Ads
  },
  data() {
    return {
      isMoreCourses: true,
      isPageBack: false,
      prewPage: "home",
      isLoad: true,
    };
  },
  watch: {
    page(page) {
      this.isPageBack = page.id < this.prewPage;
      this.prewPage = page.id;
    },
  },
  computed: {
    ...mapState(["page"]),
  },
  methods: {
    ...mapActions(["initCourses"]),
  },
  async mounted() {
    await this.initCourses();
    this.isLoad = false;
    // setTimeout(async () => {
    //   await this.initCourses();
    //   this.isLoad = false;
    // }, 2000);
  },
};
</script>

<style lang="scss">
.app {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .wrapper > .content {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--navbar-height));
    display: flex;
    flex: 1;
    overflow: hidden;

    .inner {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }

  & > .loader {
    background: var(--main);
    color: var(--white);
    font-size: 45px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.page-next-leave-active,
.page-next-enter-active {
  position: absolute;
  transition: 0.4s;
}
.page-next-enter {
  transform: translate(100%, 0);
}
.page-next-leave-to {
  transform: translate(-100%, 0);
}

.page-back-leave-active,
.page-back-enter-active {
  position: absolute;
  transition: 0.4s;
}
.page-back-enter {
  transform: translate(-100%, 0);
}
.page-back-leave-to {
  transform: translate(100%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
