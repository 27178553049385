<template>
  <Modal v-model="isSection">
    <div v-if="isSection" class="article-section">
      <div class="header">
        <IconArrow class="icon" @click="setSection(null)" />
        <div class="title">{{ section.title }}</div>
      </div>

      <div class="content">
        <ArticleItem
          v-for="(item, index) of section.list"
          :key="index"
          :course="item"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Modal from "@/components/base/modal/ModalPanel";
import IconArrow from "@/assets/images/svg/arrow-left.svg?inline";
import ArticleItem from "@/components/article/ArticleItem";

export default {
  name: 'ArticleSection',
  components: { Modal, IconArrow, ArticleItem },
  data() {
    return {}
  },
  computed: {
    ...mapState(["section"]),
    isSection() {
      return Boolean(this.section);
    },
  },
  methods: {
    ...mapMutations(["setSection"])
  }
}
</script>

<style lang="scss">
.article-section {
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid var(--support-silver);

    & > .title {
      color: var(--text-black);
      font-weight: 500;
      font-size: 21px;
    }

    .icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      fill: var(--text-black);
    }
  }

  .content {
    overflow: auto;
    height: calc(100% - var(--navbar-height));
    line-height: 24px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .article {
      width: calc(50% - 10px);
      margin-bottom: 10px;

      &:nth-child(odd) {
        margin-right: 5px;
      }

      &:nth-child(even) {
        margin-left: 5px;
      }

      &.section {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
  }
}
</style>