<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'Ads',
  data() {
    return {
      listAds: [
        'upgulpinon|4844014',
        'upgulpinon|4860080',
        'upgulpinon|4860081',
        'upgulpinon|4860084',
        'upgulpinon|4860085',
        'upgulpinon|4860086',
        'upgulpinon|4860087',
        'upgulpinon|4860582',
        'upgulpinon|4860583',
        'upgulpinon|4860584',
        'upgulpinon|4860585',
        'upgulpinon|4860586',
        'upgulpinon|4860588',
        'upgulpinon|4860589',
        'upgulpinon|4860590',
        'upgulpinon|4860591',
        'upgulpinon|4860592',
        'upgulpinon|4860593',
        'upgulpinon|4860594',
        'upgulpinon|4862761',
        'upgulpinon|4862762',
        'upgulpinon|4862763',
        'upgulpinon|4862764',
        'upgulpinon|4862766',
        'upgulpinon|4862767',
        'upgulpinon|4862768',
        'upgulpinon|4862771',
        'upgulpinon|4862772',
        'upgulpinon|4862773',
        'upgulpinon|4862774',
        'upgulpinon|4862779',
        'upgulpinon|4862780',
        'upgulpinon|4862781',
        'upgulpinon|4862782',
        'upgulpinon|4862783',
        'upgulpinon|4862784',
        'upgulpinon|4862785',
        'upgulpinon|4862786',
        'upgulpinon|4862787',
        'upgulpinon|4862788',
        'upgulpinon|4862797',
        'upgulpinon|4862798',
        'upgulpinon|4862800',
        'upgulpinon|4862801',
        'upgulpinon|4862804',
        'upgulpinon|4862805',
        'upgulpinon|4862806',
        'upgulpinon|4862810',
        'upgulpinon|4862812',
        'untimburra|4848584'
      ],
      
    }
  },
  watch: {
    isAds() {
      // Delete other ads
      let listScript = document.querySelectorAll("script");
      let ads = this.listAds[this.isAds];

      if (listScript) {
        listScript = listScript.forEach(item => {
          if (item.src.includes('upgulpinon.com')) {
            item.remove();
          }
        })
      }

      if (ads !== undefined) {
        let scriptEl = document.createElement('script');
        ads = ads.split('|');
        const type = ads[0];
        const id = ads[1]

        if (type === 'upgulpinon') {
          scriptEl.setAttribute('src', `//upgulpinon.com/1?z=${id}`);
          scriptEl.setAttribute('async', 'async');
          scriptEl.setAttribute('data-cfasync', 'false');
        } else if (type === 'untimburra') {
          scriptEl.setAttribute('src', `//untimburra.com/400/${id}`);
        }

        document.body.appendChild(scriptEl);
      }
    }
  },
  computed: {
    ...mapState(['isAds'])
  },
}
</script>

<style lang="scss">
.ads {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>