import axios from "axios";

export default class Api {
  constructor() {
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_API}v1/`,
    });
  }

  async getCourse() {
    const result = await Promise.all([
      this.getTest(),
      this.getArticle()
    ])

    return {
      test: result[0],
      ...result[1]
    }
  }

  async getTest() {
    const { data } = await this.client.post('', {
      id: process.env.VUE_APP_ID_COURSE,
      type: 'test'
    });

    return data
  }

  async getArticle() {
    const { data } = await this.client.post('', {
      id: process.env.VUE_APP_ID_COURSE,
      type: 'article'
    });

    return {
      ...data.course,
      article: data.article,
    }
  }
}

export const api = new Api();
