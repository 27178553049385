<template>
  <Modal v-model="isArticle">
    <div v-if="isArticle" class="more-courses">
      <div class="header">
        <IconArrow class="icon" @click="setArticle(null)" />
        <div class="title">{{ article.title }}</div>
      </div>

      <div class="content">
        <div class="text" v-html="article.text"></div>

        <footer :class="{ first: !nav.prev }">
          <div v-if="nav.prev" class="prev">
            <IconArrow class="icon" @click="setArticle(nav.prev)"/>
          </div>
          <div v-if="nav.next" class="next" @click="setArticle(nav.next)">
            <div class="title">Следующая статья</div>
            <IconArrow class="icon" />
          </div>
        </footer>
      </div>
    </div>
  </Modal>
</template>

<script>
import { setLocal } from "@/helpers";
import { mapState, mapMutations } from "vuex";

import Modal from "@/components/base/modal/ModalPanel";
import IconArrow from "@/assets/images/svg/arrow-left.svg?inline";

export default {
  name: "MoreCourses",
  components: {
    Modal,
    IconArrow,
  },
  data() {
    return {
      intervalArticle: "",
      nav: {
        prev: null,
        next: null
      },
    };
  },
  watch: {
    article() {
      if (this.article) {
        clearInterval(this.intervalArticle);

        setTimeout(() => {
          this.setScrollTop();

          this.intervalArticle = setInterval(() => {
            let local = this.local.article;
            const el = document.querySelector(".more-courses .content");
            const fullHeight = el.scrollHeight;
            const scroll = Math.round(el.scrollTop + el.offsetHeight);

            let article = {
              hash: this.article.hash,
              progress: (scroll / fullHeight) * 100,
            };

            article.progress = article.progress > 90 ? 100 : article.progress
            this.article.progress = article.progress;

            const index = local.findIndex((item) => item.hash === article.hash);

            if (index !== -1) {
              local[index] = article;
            } else {
              local.push(article);
            }

            setLocal("article", JSON.stringify(local));
            setLocal("last", this.article.hash);
          }, 1000);
        }, 100);

        this.getPrevNextArticle();
      } else {
        clearInterval(this.intervalArticle);
      }
    },
  },
  computed: {
    ...mapState(["local", "article", "articles"]),
    isArticle() {
      return Boolean(this.article);
    },
  },
  methods: {
    ...mapMutations(["setArticle"]),
    setScrollTop() {
      const el = document.querySelector(".more-courses .content");
      const fullHeight = el.scrollHeight;

      let scrollTop = +((fullHeight * this.article.progress) / 100 - window.innerHeight);
      el.scrollTop = scrollTop;
    },
    getPrevNextArticle() {
      this.nav = {
        prev: null,
        next: null
      };

      const index = this.articles.findIndex((item) => item.id === this.article.id);
      if (index) this.nav.prev = this.articles[index - 1]
      if (index !== this.articles.length) {
        const article = this.articles[index + 1]
        if (!article.section) {
          this.nav.next = article
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.more-courses {
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid var(--support-silver);

    & > .title {
      color: var(--text-black);
      font-weight: 500;
      font-size: 21px;
    }

    .icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      fill: var(--text-black);
    }
  }

  .content {
    overflow: auto;
    height: calc(100% - var(--navbar-height));
    line-height: 24px;

    .text {
      min-height: calc(100% - var(--navbar-height));
      padding: 15px 15px 30px;
      word-break: break-word;
    }
  }

  footer {
    height: var(--navbar-height);
    border-top: 1px solid var(--support-silver);
    width: 100%;
    padding: 0 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.first {
      justify-content: flex-end;
    }

    .icon {
      width: 18px;
      height: 18px;
      fill: #fff;
    }

    & > .prev {
      width: 45px;
    }

    & > .next {
      width: max-content;
      padding: 0 15px;
      font-size: 14px;

      & > .icon {
        transform: rotate(180deg);
        margin-left: 15px;
      }
    }

    & > .prev,
    & > .next {
      height: 45px;
      background: var(--main);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: #fff;
    }
  }
}
</style>
