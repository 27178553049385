<template>
  <div class="statistic-circle-item">
    <div class="pie-chart" :style="pieStyles"></div>
    <div class="content">
      <div class="title">{{ stat.title }}</div>
      <div class="count">{{ stat.count }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stat: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    pieStyles() {
      let acum = 0;
      let pie = [this.stat];
      pie.push({
        colorMain: this.stat.colorSecond,
        value: 100 - this.stat.value,
      });
      let styles = pie.map(
        (segment) => `${segment.colorMain} 0 ${(acum += segment.value)}%`
      );

      return {
        background: `conic-gradient( ${styles.join(",")} )`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.statistic-circle-item {
  background: var(--white);
  border: 1px solid var(--support-silver);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 17px;

  .pie-chart {
    border-radius: 50%;
    width: 73px;
    height: 73px;
  }

  .content {
    margin-left: 15px;
    text-align: center;

    .title {
      font-size: 14px;
      margin-bottom: 5px;
      color: var(--text-silver);
    }

    .count {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
</style>
