<template>
  <div class="statistic">
    <header>
			<div class="title">Прогресс прохождения</div>
		</header>

    <div class="list">
      <StatisticCirlceItem
        v-for="(item, index) of statistics"
        :key="index"
        :stat="item"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StatisticCirlceItem from "@/components/base/StatisticCirlceItem";

export default {
  name: "Statistic",
  components: {
    StatisticCirlceItem,
  },
  computed: {
    ...mapState(['articles', 'tests']),
    statistics() {
      let articleRead = this.articles.filter((item) => item.progress === 100).length
      let articleProcent = (articleRead / this.articles.length) * 100

      let testFinish = this.tests.filter((item) => item.finish === true).length
      let testProcent = (testFinish / this.tests.length) * 100

      return {
        read: {
          colorMain: "#7A39E4",
          colorSecond: '#0c6fe314', 
          value: articleProcent,
          title: 'Прочитано',
          count: articleRead,
        },
        tests: {
          colorMain: "#E3C10C",
          colorSecond: '#e3c00c14', 
          value: testProcent,
          title: 'Тесты',
          count: testFinish,
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.statistic {
  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      color: var(--text-black);
      font-size: 17px;
      font-weight: 600;
    }

    .all {
      font-size: 15px;
      color: var(--text-silver);
    }
  }

  .list {
    width: calc(100% + 30px);
    display: flex;
    overflow: auto;
    margin-left: -15px;
    padding: 0 15px 15px;

    .statistic-circle-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }
}
</style>
