<template>
  <div class="status-bar">
    <div
      v-for="(item, index) in all"
      :key="index"
      class="item"
      :class="{ active: item.id <= current, good: item.result === 'good', bad: item.result === 'bad' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "StatusBar",
  props: {
    all: {
      type: Array
    },
    current: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.status-bar {
  display: flex;
  width: 100%;

  .item {
    width: 100%;
    height: 3px;
    background: #ffffff6e;
    margin-right: 1%;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background: var(--white);
    }

    &.good {
      background: #85c665;
    }

    &.bad {
      background: #ea847d;
    }
  }
}
</style>
