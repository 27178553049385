import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import Toast from "vue-toastification";

import "./assets/scss/main.scss";
import "vue-toastification/dist/index.css";

Vue.use(Toast)

// import hljs from "highlight.js";
// hljs.initHighlightingOnLoad();

Vue.config.productionTip = false;


// Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
  Vue,
  dsn: "https://63f709c6303c46088c9e784a19c86ce9@o1141338.ingest.sentry.io/6199490",
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", "python.coursme.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
// .Sentry

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
