<template>
  <div class="articles">
    <div class="title">Курс</div>

    <div v-if="articles" class="list">
      <ArticleItem
        v-for="(item, index) of articles"
        :key="index"
        :course="item"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ArticleItem from "@/components/article/ArticleItem";

export default {
  name: "Articles",
  components: {
    ArticleItem,
  },
  computed: {
    ...mapState(["articles"]),
  },
};
</script>

<style lang="scss" scoped>
.articles {
  padding: 15px;

  & > .title {
    color: var(--text-black);
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    .article {
      width: calc(50% - 10px);
      margin-bottom: 10px;

      &:nth-child(odd) {
        margin-right: 5px;
      }

      &:nth-child(even) {
        margin-left: 5px;
      }

      &.section {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
  }
}
</style>
