import Vue from "vue";
import Vuex from "vuex";
import { api } from "@/api";
import { getLocal } from "@/helpers";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: "",
    page: {
      id: 1,
      title: "Главная",
      component: "home",
    },
    isMoreCourses: false,
    articles: null,
    article: null,
    tests: null,
    test: null,
    local: {},
    section: null,
    articleSection: null,

    isAds: -1
  },
  actions: {
    async initCourses({ commit, dispatch }) {
      let course = await api.getCourse();
      console.log('course', course);
      commit("setLocal");
      await Promise.all([
        dispatch("formirationArticle", course.article),
        dispatch("formirationTests", course.test),
      ]);
      commit("setTitle", course.title);
    },
    formirationArticle({ commit, state }, article) {
      let listProgress = state.local.article;
      article = article.map((item) => {
        let progress = listProgress.find(
          (article) => article.hash === item.hash
        );
        progress = progress ? progress.progress : 0;

        return {
          ...item,
          progress,
        };
      });

      // Получаем секции, обьеденяем и потом добавляем к массиву
      let sections = [];
      for (let item of article) {
        const section = item.section
        if (section) {
          if (!sections[section]) {
            sections[section] = {
              section: true,
              title: section,
              list: []
            }
          }
          
          sections[section].list.push(item)
        }
      }

      article = article.filter(item => !item.section)
      
      for (let key in sections) {
        const section= sections[key]
        section.list = section.list.map(item => {
          delete item.section
          return {
            key,
            ...item
          }
        }) 
        article.push(section)
      }

      commit("setArticles", article);
    },
    formirationTests({ commit, state }, tests) {
      let localTests = state.local.tests;

      tests = tests.map((item) => {
        item.questions = JSON.parse(item.questions);
        let status = null;
        let decided = 0;
        let finish = false;

        let test = localTests.find((test) => test.hash === item.hash);

        if (test) {
          status = test.status;
          decided = test.decided;
          finish = test.finish;
        }

        return {
          ...item,
          status,
          length: item.questions.length,
          decided,
          finish,
        };
      });

      commit("setTests", tests);
    },
  },
  mutations: {
    setIsAds(state) {
      state.isAds = ++state.isAds
    },
    setPage(state, page) {
      state.page = page;
    },
    setIsMoreCourses(state, res) {
      state.isMoreCourses = res;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setArticles(state, articles) {
      state.articles = articles;
    },
    setTests(state, tests) {
      state.tests = tests;
    },
    setArticle(state, article) {
      state.article = article;

      // ADS
      if (article) {
        state.isAds = ++state.isAds
      }
    },
    setTest(state, test) {
      state.test = test;
    },
    setSection(state, section) {
      state.section = section
    },
    setArticleSection(state, article) {
      state.articleSection = article

      // ADS
      if (article) {
        state.isAds = ++state.isAds
      }
    },
    setLocal(state) {
      let article = getLocal("article");
      let tests = getLocal("tests");

      if (article) {
        article = JSON.parse(article);
      }

      if (tests) {
        tests = JSON.parse(tests);
      }

      state.local = {
        article: article || [],
        tests: tests || [],
      };
    },
  },
  modules: {},
});
