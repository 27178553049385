<template>
  <Modal v-model="isTest">
    <div v-if="isTest" class="question-wrapper">
      <template v-if="!isFinish">
        <div class="header">
          <div class="title">
            Вопрос {{ current }}<span>/{{ size }}</span>
          </div>
          <StatusBar :all="all" :current="current" />
        </div>

        <Question v-model="answer" :question="question" />

        <div class="bottom">
          <div v-if="listResult.length !== size - 1" class="finish" @click="finish">Завершить</div>
          <div class="next" @click="next">
            {{ listResult.length == size - 1 ? "Завершить" : "Дальше" }}
          </div>
        </div>
      </template>
      <ResultTest v-else :result="dataFinish" />
    </div>
  </Modal>
</template>

<script>
import { setLocal } from "@/helpers";
import { mapState, mapMutations } from "vuex";

import Modal from "@/components/base/modal/ModalPanel";
import StatusBar from "@/components/test/questions/StatusBar";
import Question from "@/components/test/questions/Question";
import ResultTest from "@/components/test/ResultTest";

export default {
  name: "QuestionWrapper",
  components: {
    ResultTest,
    StatusBar,
    Question,
    Modal,
  },
  data() {
    return {
      isFinish: false,
      current: 1,
      all: [],
      answer: "",
      listResult: [],
      decided: 0,
      status: false,
      size: 0,
      dataFinish: {
        status: false,
        decided: 0,
        size: 0
      }
    };
  },
  watch: {
    test() {
      if (this.test) {
        console.log('this.test.questions', this.test.questions);
        this.test.questions.sort(() => Math.random()-0.5);
        console.log('this.test.questions', this.test.questions);

        this.isFinish = false;
        this.current = 1;
        this.all = [];
        this.answer = "";
        this.listResult = [];
        this.decided = 0;
        this.status = false;
        this.size = this.test.questions.length
        this.dataFinish = {
          status: false,
          decided: 0,
          size: this.size
        }

        this.all = this.test.questions.map((item, index) => {
          return { id: ++index }
        });
      }
    },
  },
  computed: {
    ...mapState(["test", "tests", "local"]),
    isTest() {
      return Boolean(this.test);
    },
    question() {
      const index = this.current - 1;
      return this.test.questions[index];
    },
  },
  methods: {
    ...mapMutations(["setTest", "setTests", "setIsAds"]),
    finish() {
      const test = this.test;
      let tests = this.tests.map((item) => {
        if (item.hash === test.hash) {
          item.status = this.status;
          item.decided = this.decided;
          item.finish = true;
        }
        return item;
      });
      this.setTests(tests);
      this.setIsAds()
      this.isFinish = true;
    },
    next() {
      if (this.answer) {
        this.listResult.push({
          ...this.question,
          chosen: this.answer,
        });

        let answer = 'bad'
        if (this.answer === this.question.answer) {
          this.decided += 1;
          answer = 'good'
        }

        // For status bar
        this.all[this.current - 1].result  = answer

        this.answer = "";

        if (this.listResult.length === this.size) {
          let local = this.local.tests;

          let procentResult = Math.round(
            (this.decided / this.listResult.length) * 100
          );
          this.status = procentResult > 65 ? true : false;

          let test = {
            hash: this.test.hash,
            finish: true,
            status: this.status,
            decided: this.decided,
          };

          const index = local.findIndex((item) => item.hash === this.test.hash);

          if (index !== -1) {
            local[index] = test;
          } else {
            local.push(test);
          }

          // For result modal
          this.dataFinish = {
            ...this.dataFinish,
            ...test
          }

          setLocal("tests", JSON.stringify(local));
          this.finish();
        } else {
          this.current += 1;
        }
      } else {
        this.$toast.error("Ответьте на вопрос", {
          position: "top-center",
          timeout: 1549,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: true,
          draggablePercent: 0.96,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header {
    background: var(--main);
    border-radius: 0 0 25px 25px;
    margin-bottom: 20px;
    padding: 15px 15px 25px;

    & > .title {
      color: var(--white);
      font-weight: 500;
      font-size: 23px;
      margin-bottom: 20px;
      text-align: center;

      span {
        font-size: 18px;
      }
    }
  }

  .question {
    height: 100%;
    padding-bottom: 50px;
    overflow: auto;
  }

  .bottom {
    position: fixed;
    bottom: -1%;
    left: 0;
    width: 100%;
    background: #f9f9f9;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    .finish {
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-silver);
    }

    .next {
      width: 100%;
      border-radius: 6px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--main);
      color: var(--white);
    }
  }
}
</style>
