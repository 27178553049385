<template>
  <div class="article" :class="{ isCompletd, section: course.section }" @click="choseArticle">
    <template v-if="course.section">
      <div class="title">{{ course.title }}</div>
      <div class="count">{{ countListArticle }}</div>
    </template>
    <template v-else>
      <IconOk v-if="isCompletd" class="icon-completed" />
      <div class="title">{{ course.title }}</div>
      <div
        v-if="!isCompletd"
        class="progress"
        :style="{ width: `${course.progress}%` }"
      ></div>
    </template>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import IconOk from "@/assets/images/svg/check-ok.svg?inline";

export default {
  name: 'Article',
  components: {
    IconOk,
  },
  props: {
    course: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isCompletd() {
      return this.course.progress === 100;
    },
    countListArticle() {
      const size = this.course.list.length;

      let message = `уроков`;
      if (size === 1) message = 'урок'
      if  (size > 1 && size <= 4) message = 'урока'

      return `${size} ${message}`;
    }
  },
  methods: {
    ...mapMutations(['setArticle', 'setArticleSection', 'setSection']),
    choseArticle() {
      if (this.course.section) {
        this.setSection(this.course)
      } else {
        if (this.course.key) {
          this.setArticleSection(this.course)
        } else {
          this.setArticle(this.course)
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.article {
  position: relative;
  width: 100%;
  height: 67px;
  border-radius: 6px;
  background: var(--main-second-block);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  overflow: hidden;

  &.section {
    display: flex;
    flex-direction: column;
    height: 110px;

    & > .title {
      font-size: 16px;
      margin-bottom: 5px;
    }

    & > .count {
      color: var(--main);
      background: #f6b62c1a;
      padding: 6px 16px;
      border-radius: 50px;
      font-size: 15px;
    }
  }

  &.isCompletd {
    color: var(--black);
    background: var(--main);
  }

  .icon-completed {
    position: absolute;
    right: -25px;
    fill: rgba(255, 255, 255, 0.13);
    width: 90px;
    height: 90px;
  }

  .progress {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    background: var(--main);
    opacity: 0.2;
    z-index: 0;
  }
}
</style>