//last - последняя остановка в курсе
//statistic - статистика пройденых курсов и тестов
//tests - пройденые тесты. Процент и хэш
//courses - пройденные курсы. кол-во правильных, хэш

export const setLocal = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocal = (key) => {
  return localStorage.getItem(key);
};
