<template>
  <Modal v-model="isMoreCoursesValue">
    <div class="more-courses" @click="isMoreCoursesValue = false">
      <div class="header">
        <div class="title">Наши курсы</div>
        <IconClose class="icon" />
      </div>

      <div class="content">
        <iframe src="https://coursme.com/templates/our-apps/"></iframe>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Modal from "@/components/base/modal/ModalPanel";
import IconClose from "@/assets/images/svg/close.svg?inline";

export default {
  name: "MoreCourses",
  components: {
    Modal,
    IconClose,
  },
  computed: {
    ...mapState(["isMoreCourses"]),
    isMoreCoursesValue: {
      get() {
        return this.isMoreCourses;
      },
      set(val) {
        this.setIsMoreCourses(val);
      },
    },
  },
  methods: {
    ...mapMutations(["setIsMoreCourses"]),
  },
};
</script>

<style lang="scss" scoped>
.more-courses {
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 60px;

    & > .title {
      color: var(--text-black);
      font-weight: bold;
      font-size: 32px;
    }

    .icon {
      width: 16px;
      height: 16px;
      fill: var(--text-silver);
    }
  }

  .content {
    height: calc(100% - 60px);

    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
