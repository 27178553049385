<template>
  <div class="result-test">
    <IconCongrats v-if="result.status" class="icon" />
    <IconSadSmile v-else class="icon sad" />

    <div class="title">
      {{ result.status ? 'Тест пройден' : 'Тест не пройден' }}
    </div>
    <div class="status">Правильно {{ result.decided }} из {{ result.size }}</div>

    <div class="group">
      <template v-if="result.status" >
        <div class="btn" @click="setTest(null)">Завершить</div>
        <div class="btn silver" @click="nextTest">Следующий тест</div>
      </template>
      <template v-else>
        <div class="btn" @click="nowTest">Пройти еще раз</div>
        <div class="btn silver" @click="showArticles">Вернуться к теории</div>
        <div class="btn no-background" @click="setTest(null)">Завершить</div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IconCongrats from "@/assets/images/svg/congrats.svg?inline";
import IconSadSmile from "@/assets/images/svg/sad-smile.svg?inline";

export default {
  name: 'ResultTest',
  components: { IconCongrats, IconSadSmile },
  props: {
    result: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(["test", "tests"]),
  },
  methods: {
    ...mapMutations(["setTest", "setPage"]),
    nowTest() {
      const test = this.test;
      this.setTest(null)
      this.setTest(test)
    },
    showArticles() {
      this.setPage({
        id: 2,
        title: "Курс",
        component: "articles",
      })
      this.setTest(null);
    },
    nextTest() {
      const index = this.tests.findIndex((item) => item.id === this.test.id);
      this.setTest(this.tests[index + 1]);
    }
  }
}
</script>

<style lang="scss">
.result-test {
  background: #fffcfa;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    margin: 30px 0;
    &.sad {
      margin: 50px 0 40px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .status {
    color: #666666;
    margin-bottom: 40px;
  }

  .group {
    .btn {
      width: 200px;
      border-radius: 6px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--main);
      color: var(--white);
      margin-bottom: 10px;

      &.silver {
        background: #f3f3f3;
        color: #000;
      }

      &.no-background {
        background: none;
        color: #9a9a9a;
      }
    }
  }
}
</style>
