<template>
  <div class="question">
    <div class="title" v-html="question.title" />

    <div class="list-answer">
      <div
        v-for="(item, index) of question.answers"
        :key="index"
        :class="{ active: answer === item }"
        class="item"
        @click="answer = item"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionItem",
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    answer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  }
};
</script>

<style lang="scss" scoped>
.question {
  width: 100%;

  .title {
    padding: 0 15px 25px;
    line-height: 18px;
  }

  .list-answer {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px 15px;

    .item {
      overflow-wrap: anywhere;
      width: calc(50% - 5px);
      border: 2px solid var(--support-silver);
      border-radius: 6px;
      min-height: 67px;
      padding: 10px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      text-transform: capitalize;

      &:nth-child(odd) {
        margin-right: 5px;
      }

      &:nth-child(even) {
        margin-left: 5px;
      }

      &.active {
        background: var(--main);
        color: var(--black);
      }
    }
  }
}
</style>
