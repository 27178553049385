<template>
  <div class="lats-tests">
    <header>
			<div class="title">Последние тесты</div>
			<div class="all" @click="setPage(page)">Все тесты</div>
		</header>
    <div v-if="listTests.length" class="list">
      <TestItem v-for="(item, index) of listTests" :key="index" :test="item" />
    </div>
    <div v-else class="empty">
      <IconEmpty class="icon" />
      <div class="title">Вы еше не проходили тесты</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import TestItem from "@/components/test/TestItem";
import IconEmpty from "@/assets/images/svg/empty.svg?inline";

export default {
  name: "LatsTests",
  components: { TestItem, IconEmpty },
  data() {
    return {
      listTests: [],
      page: {
        id: 3,
        title: "Тесты",
        component: "tests",
      }
    };
  },
  computed: {
    ...mapState(["tests", "local"]),
  },
  methods: {
    ...mapMutations(["setPage"]),
  },
  mounted() {
    if (this.local.tests) {
      let localTests = this.local.tests.map((item) => 
        this.tests.find((test) => test.hash === item.hash)
      );
      this.listTests = localTests.reverse().slice(0, 3);
    }
    
  }
};
</script>

<style lang="scss" scoped>
.lats-tests {
  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      color: var(--text-black);
      font-size: 17px;
      font-weight: 600;
    }

    .all {
      font-size: 15px;
      color: var(--text-silver);
    }
  }

  .list {
    .test-item {
      margin-bottom: 10px;
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 79px;
      height: 79px;
      fill: var(--support-silver);
      margin-bottom: 20px;
    }

    .title {
      font-size: 16px;
      color: var(--support-silver);
    }
  }
}
</style>
