<template>
  <div class="lats-tests">
    <header>
			<div class="title">Доп. материалы</div>
			<div class="all" @click="setPage(page)">Все материалы</div>
		</header>
    <div v-if="list" class="list">
      <ArticleItem
        v-for="(item, index) of list"
        :key="index"
        :course="item"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ArticleItem from "@/components/article/ArticleItem";

export default {
  name: "AdditionMaterial",
	components: {
    ArticleItem,
  },
  data() {
    return {
      list: null,
			page: {
				id: 2,
				title: "Курс",
				component: "articles",
			}
    };
  },
  computed: {
    ...mapState(["articles"]),
  },
	methods: {
    ...mapMutations(["setPage"]),
  },
  mounted() {
		const articles = this.articles.filter((item) => item.section);
		this.list = articles.slice(0, 2);
  }
};
</script>

<style lang="scss" scoped>
.lats-tests {
  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;


    .title {
      color: var(--text-black);
      font-size: 17px;
      font-weight: 600;
    }

    .all {
      font-size: 15px;
      color: var(--text-silver);
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;

    .article {
      width: 100%;
      margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
    }
  }	
}
</style>
