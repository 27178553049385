<template>
  <div class="tests">
    <div class="title">Тесты</div>
    <div class="list">
      <TestItem v-for="(item, index) of tests" :key="index" :test="item" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TestItem from "@/components/test/TestItem";

export default {
  name: "Tests",
  components: { TestItem },
  computed: {
    ...mapState(["tests"]),
  },
};
</script>

<style lang="scss" scoped>
.tests {
  width: 100%;
  padding: 15px;

  & > .title {
    color: var(--text-black);
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .list {
    .test-item {
      margin-bottom: 10px;
    }
  }
}
</style>
